import React from "react"
import Section from "../../../components/section/Section"

import sovaImg from "../../../assets/images/about-us/why-sova-about-us-sova.ai.svg"

interface Imgs {
  [k: string]: string
}

const images: Imgs = {
  sovaImg: sovaImg,
}

const AboutUsSectionSecond = ({ className, data }: { className: string; data: any }) => {
  const { heading, paragraphs, imgAlt, imgSrc } = data.data.content.AboutUsSectionSecondConfig

  return (
    <Section className={className}>
      <div className="sova-section-container">
        <h2 className="sova-section-child  sova-h2">{heading}</h2>
        <div className="sova-section-container sova-section-container_row">
          <div className="sova-section-child-container">
            {paragraphs.map((paragraph: string, key: number) => (
              <p key={key} className={`sova-section-child sova-section-paragraph sova-section-paragraph_aboutUs_2-${key} sova-section-child-text sova-p-tiny`}>
                {paragraph}
              </p>
            ))}
          </div>
          <img className="sova-section-child sova-section-img sova-section-img_aboutUS-aboutUsSectionSecond" src={images[imgSrc]} alt={imgAlt} />
        </div>
      </div>
    </Section>
  )
}

AboutUsSectionSecond.displayName = "AboutUsSectionSecond"

export default AboutUsSectionSecond
