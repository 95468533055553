import React from "react"
import Section from "../../../components/section/Section"
import Slider from "../../../components/slider/Slider"

import technologyImg from "../../../assets/images/about-us/accessible-technology-illustration-about-us-sova.ai.svg"
import contributeImg from "../../../assets/images/about-us/contribute-to-the-science-illustation-about-us-sova.ai.svg"
import simplifyImg from "../../../assets/images/about-us/simplify-the-daily-life-illustration-about-us-sova.ai.svg"

interface Imgs {
  [k: string]: string
}

const images: Imgs = {
  technologyImg: technologyImg,
  contributeImg: contributeImg,
  simplifyImg: simplifyImg,
}

const AboutUsSectionThird = ({ className, data }: { className: string; data: any }) => {
  const { heading, cards } = data.data.content.AboutUsSectionThirdConfig
  const sliderOptions = cards.map((card: any) => {
    return { imgAlt: card.imgAlt, imgSrc: images[card.imgSrc], text: card.text }
  })

  return (
    <Section className={className}>
      <div className="sova-section-container sova-section-container_aboutUs-2">
        <h2 className="sova-section-child sova-h2">{heading}</h2>
        <div className="sova-section-child-container sova-section-child-container_row sova-section-child_aboutUs-2-container">
          {cards.map(({ imgSrc, imgAlt, text }: { imgSrc: any; imgAlt: string; text: string }, key: number) => (
            <div key={key} className="sova-section-child sova-section-child_aboutUs-2-card sova-section-column sova-card">
              <img className="sova-section-child sova-section-img sova-section-child_aboutUs-2-img" src={images[imgSrc]} alt={imgAlt} />
              <p className="sova-section-child sova-section-child_aboutUs-2-card-text sova-p-tiny sova-common-text-centered">{text}</p>
            </div>
          ))}
        </div>
        <Slider options={sliderOptions} className="sova-section-child sova-section-child_aboutUs-slider" mCustomClass="aboutUs-2" />
      </div>
    </Section>
  )
}
AboutUsSectionThird.displayName = "AboutUsSectionThird"

export default AboutUsSectionThird
