import React from "react"
import Section from "../../../components/section/Section"

// import AboutUsSectionFourthConfig from "./samples/aboutUsSectionFourthSampleConfig"
import Slider from "../../../components/slider/Slider"

import StasAshmanov from "../../../assets/images/about-us/leadership/illustration1-for-leadership-aboutUs-page-sova.ai.svg"
import FedorZorkiy from "../../../assets/images/about-us/leadership/illustration2-for-leadership-aboutUs-page-sova.ai.svg"
import PavelSukhachev from "../../../assets/images/about-us/leadership/illustration4-for-leadership-aboutUs-page-sova.ai.svg"
import AnnaVlasova from "../../../assets/images/about-us/leadership/illustration5-for-leadership-aboutUs-page-sova.ai.svg"
import AleksandrArgunov from "../../../assets/images/about-us/leadership/illustration6-for-leadership-aboutUs-page-sova.ai.svg"
import EgorZubarev from "../../../assets/images/about-us/leadership/illustration7-for-leadership-aboutUs-page-sova.ai.svg"
import facebookIcon from "../../../assets/images/about-us/icons/facebook-for-aboutUs-page-sova.ai.svg"
import linkedInIcon from "../../../assets/images/about-us/icons/linkedIn-for-aboutUs-page-sova.ai.svg"

interface Imgs {
  [k: string]: string
}

const images: Imgs = {
  StasAshmanov: StasAshmanov,
  FedorZorkiy: FedorZorkiy,
  PavelSukhachev: PavelSukhachev,
  AnnaVlasova: AnnaVlasova,
  AleksandrArgunov: AleksandrArgunov,
  EgorZubarev: EgorZubarev,
  facebookIcon: facebookIcon,
  linkedInIcon: linkedInIcon,
}

interface Link {
  href: string
  socialIcon: string
}
interface Card {
  imgSrc: any
  imgAlt: string
  user: string
  position: string
  links: Link[]
}

const AboutUsSectionFourth = ({ className, data }: { className: string; data: any }) => {
  const { heading, cards } = data.data.content.AboutUsSectionFourthConfig
  const sliderOptions = cards.map((card: any) => {
    const newCard = {
      imgAlt: card.imgAlt,
      imgSrc: images[card.imgSrc],
      links: card.links,
      position: card.position,
      user: card.user,
    }
    return newCard
  })

  return (
    <Section className={className}>
      <div className="sova-section-container">
        <h2 className="sova-section-child sova-h2">{heading}</h2>
        <div className="sova-section-child sova-section-child-container sova-section-child_row sova-section-child_aboutUs-3-container">
          {cards.map(({ imgSrc, imgAlt, user, position, links }: Card, key: number) => (
            <div key={key} className={`sova-section-child-container sova-section-child_aboutUs-3-card sova-section-column sova-card sova-section-child_aboutUs-3-card-${key}`}>
              <img className="sova-section-child sova-section-img sova-section-child_aboutUs-3-img" src={images[imgSrc]} alt={imgAlt} />
              <div className="sova-section-card_body sova-section-card_aboutUs-body sova-section-column sova-section-child_col_aboutUs-3">
                <div className="sova-section-child-card_text-group">
                  <span className="sova-section-child-card_heading sova-p-medium ">{user}</span>
                  <span className="sova-section-child-card_subheading">{position}</span>
                </div>
                <div className="sova-section-child-card_social-group sova-section_aboutUs-3-card_social-group">
                  {links.map(
                    ({ href, socialIcon }, key: number) =>
                      href !== "#" && (
                        <a key={key} href={href} className={`sova-section_aboutUs-3-socialIconLink sova-section_aboutUs-3-socialIconLink-${key}`}>
                          <img src={images[socialIcon]} className="sova-section-img sova-section_aboutUs-3-socialIcon" />
                        </a>
                      )
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <Slider options={sliderOptions} className="sova-section-child sova-section-child_aboutUs-slider" mCustomClass="aboutUs-3"></Slider>
      </div>
    </Section>
  )
}
AboutUsSectionFourth.displayName = "AboutUsSectionFourth"

export default AboutUsSectionFourth
