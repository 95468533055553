import React from "react"
import Section from "../../../components/section/Section"

const AboutUsSectionFifth = ({ className, data }: { className: string; data: any }) => {
  const { heading, paragraph, btnText, btnLink } = data.data.content.AboutUsSectionFifthConfig

  return (
    <Section className={`${className} sova-section-without-margin-bottom`}>
      <div className="sova-section-container sova-section-container_aboutUs-4">
        <h2 className="sova-section-child sova-h2">{heading}</h2>
        <p className="sova-section-child sova-p-tiny sova-common-text-centered">{paragraph}</p>
        <a href={btnLink} target="blank">
          <button className="sova-btn sova-btn_secondary sova-btn_big">{btnText}</button>
        </a>
      </div>
    </Section>
  )
}

AboutUsSectionFifth.displayName = "AboutUsSectionFifth"

export default AboutUsSectionFifth
