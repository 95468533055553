import React from "react"
import { graphql } from "gatsby"
import Layout from "../layout/layout"
import SEO from "../components/SEO/seo"
import AboutUsSections from "../pagesStructures/aboutUs/aboutUsSections"

const AboutUs = ({ data, pageContext }: { data: any; pageContext: any }) => {
  const pageContent = data.strapiAboutUs.content.find((el: any) => {
    return el.language === pageContext.lang
  })

  return (
    <Layout bgCustomClass="aboutUs" pageName="aboutUs">
      <SEO title={pageContent.content.title} description="About us page" />
      <AboutUsSections data={{ ...pageContent, pageContext: pageContext }} />
    </Layout>
  )
}

export default AboutUs
export const query = graphql`
  query AboutUs($url: String) {
    strapiAboutUs(url: { eq: $url }) {
      content {
        content {
          title
          AboutUsSectionFifthConfig {
            btnLink
            btnText
            heading
            paragraph
          }
          AboutUsSectionFirstConfig {
            heading
            imgAlt
            imgSrc
            paragraph
          }
          AboutUsSectionFourthConfig {
            cards {
              imgAlt
              imgSrc
              links {
                href
                socialIcon
              }
              position
              user
            }
            heading
          }
          AboutUsSectionSecondConfig {
            heading
            imgAlt
            imgSrc
            paragraphs
          }
          AboutUsSectionThirdConfig {
            heading
            cards {
              imgAlt
              imgSrc
              text
            }
          }
        }
        language
      }
    }
  }
`
