import React from "react"

import AboutUsSectionFirst from "./aboutUsSections/AboutUsSectionFirst"
import AboutUsSectionSecond from "./aboutUsSections/AboutUsSectionSecond"
import AboutUsSectionThird from "./aboutUsSections/AboutUsSectionThird"
import AboutUsSectionFourth from "./aboutUsSections/AboutUsSectionFourth"
import AboutUsSectionFifth from "./aboutUsSections/AboutUsSectionFifth"

const sections = [
  { component: (key: number, className: string, data: any) => <AboutUsSectionFirst data={data} className={className} key={key} /> },
  { component: (key: number, className: string, data: any) => <AboutUsSectionSecond data={data} key={key} className={className} /> },
  { component: (key: number, className: string, data: any) => <AboutUsSectionThird data={data} key={key} className={className} /> },
  { component: (key: number, className: string, data: any) => <AboutUsSectionFourth data={data} key={key} className={className} /> },
  { component: (key: number, className: string, data: any) => <AboutUsSectionFifth data={data} key={key} className={className} /> },
]

const AboutUsSections = (data: any) => {
  return <>{sections.map(({ component }, i) => component(i, `sova-section sova-section-aboutUs  sova-section-aboutUs_${i}`, data))}</>
}

AboutUsSections.displayName = "AboutUsSections"

export default AboutUsSections
