import React from "react"
import Section from "../../../components/section/Section"

import communityImg from "../../../assets/images/about-us/community-illustration-about-us-sova.ai.svg"

interface Imgs {
  [k: string]: string
}

const images: Imgs = {
  communityImg: communityImg,
}

const AboutUsSectionFirst = ({ className, data }: { className: string; data: any }) => {
  const { heading, paragraph, imgAlt, imgSrc } = data.data.content.AboutUsSectionFirstConfig

  return (
    <Section className={className}>
      <div className="sova-section-container">
        <h2 className="sova-section-child  sova-h2">{heading}</h2>
        <p className="sova-section-child sova-section-paragraph sova-p-tiny sova-common-text-centered">{paragraph}</p>
        <img src={images[imgSrc]} alt={imgAlt} className="sova-section-child sova-section-img" />
      </div>
    </Section>
  )
}

AboutUsSectionFirst.displayName = "AboutUsSectionFirst"

export default AboutUsSectionFirst
